import React, { Component } from 'react';
import p from '../utils/Transfrom';
import ContentLoader from 'react-content-loader'
import FastImage from 'react-native-fast-image';

export class DiscoverContentLoading extends Component {
  render () {
    return (
      <ContentLoader
        height={p(400)}
        width={p(1100)}
        speed={1}
        uniqueKey={this.props.uniquekey}
        primarycolor="#f1f1f1"
        secondarycolor="#ecebeb"
      >
        <rect x={p(30)} y={p(30)} rx={p(19)} ry={p(19)} width={p(220)} height={p(38)} />
        <rect x={p(30)} y={p(100)} rx={p(19)} ry={p(19)} width={p(192)} height={p(248)} />
        <rect x={p(242)} y={p(100)} rx={p(19)} ry={p(19)} width={p(192)} height={p(248)} />
        <rect x={p(454)} y={p(100)} rx={p(19)} ry={p(19)} width={p(192)} height={p(248)} />
        <rect x={p(666)} y={p(100)} rx={p(19)} ry={p(19)} width={p(192)} height={p(248)} />
      </ContentLoader>
    );
  }
}

export class PostItemContentLoading extends Component {
  render () {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(300)}
        width={p(630)}>
        <circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(36)} />
        <rect x={p(100)} y={p(20)} rx={p(6)} ry={p(6)} width={p(200)} height={p(12)} />
        <rect x={p(100)} y={p(60)} rx={p(6)} ry={p(6)} width={p(100)} height={p(12)} />

        <rect x={p(0)} y={p(160)} rx={p(6)} ry={p(6)} width={p(630)} height={p(15)} />
        <rect x={p(0)} y={p(195)} rx={p(6)} ry={p(6)} width={p(630)} height={p(15)} />
        <rect x={p(0)} y={p(230)} rx={p(6)} ry={p(6)} width={p(300)} height={p(15)} />
      </ContentLoader>
    );
  }
}

export class PostDetailItemContentLoading extends Component {
  render () {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(800)}
        width={p(690)}>
        <circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(36)} />
        <rect x={p(100)} y={p(20)} rx={p(6)} ry={p(6)} width={p(200)} height={p(15)} />
        <rect x={p(100)} y={p(55)} rx={p(6)} ry={p(6)} width={p(100)} height={p(15)} />
        <rect x={p(0)} y={p(150)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(200)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(250)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(300)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(350)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(400)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(450)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(500)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(550)} rx={p(6)} ry={p(6)} width={p(690)} height={p(20)} />
        <rect x={p(0)} y={p(600)} rx={p(6)} ry={p(6)} width={p(300)} height={p(20)} />
      </ContentLoader>
    );
  }
}
export class NormalUserContentLoading extends Component {
  render () {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(800)}
        width={p(690)}>
        <circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(36)} />
        <rect
          x={p(100)}
          y={p(20)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(15)}
        />
        <rect
          x={p(100)}
          y={p(55)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <rect
          x={p(0)}
          y={p(150)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(200)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(250)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(300)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(350)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(400)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(450)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(500)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(550)}
          rx={p(6)}
          ry={p(6)}
          width={p(690)}
          height={p(20)}
        />
        <rect
          x={p(0)}
          y={p(600)}
          rx={p(6)}
          ry={p(6)}
          width={p(300)}
          height={p(20)}
        />
      </ContentLoader>
    );
  }
}
export class FollowItemContentLoading extends Component {
  render () {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#f1f1f1"
        secondaryColor="#ecebeb"
        height={p(80)}
        width={p(690)}>
        <circle cx={p(40)} cy={p(40)} r={p(40)} x={p(0)} y={p(28)} />
        <rect
          x={p(100)}
          y={p(16)}
          rx={p(6)}
          ry={p(6)}
          width={p(200)}
          height={p(15)}
        />
        <rect
          x={p(100)}
          y={p(52)}
          rx={p(6)}
          ry={p(6)}
          width={p(100)}
          height={p(15)}
        />
        <rect
          x={p(478)}
          y={p(14)}
          rx={p(26)}
          ry={p(26)}
          width={p(112)}
          height={p(48)}
        />
      </ContentLoader>
    );
  }
}

export class NewsItemContentLoading extends Component {
  render () {
    return (
      <ContentLoader
        speed={1}
        primaryColor="#F6F5FA"
        secondaryColor="#F1F1F1"
        height={p(152)}
        style={{ position: 'relative' }}
        width={p(650)}>

        <rect
          x={p(0)}
          y={p(0)}
          rx={p(15)}
          ry={p(15)}
          width={p(416)}
          height={p(28)}
        />
        <rect
          x={p(0)}
          y={p(50)}
          rx={p(15)}
          ry={p(15)}
          width={p(416)}
          height={p(28)}
        />
        <rect
          x={p(0)}
          y={p(125)}
          rx={p(6)}
          ry={p(6)}
          width={p(120)}
          height={p(14)}
        />
        <FastImage
          source={require('../images/kiki_preload.webp')}
          style={{ position: 'absolute', right: 0, top: 0, width: p(200), height: p(152) }} />
      </ContentLoader>
    );
  }
}
